window.angular.module('MyHippoProducer.Components').component('notifications', {
    restrict: 'E',
    scope: {},
    templateUrl: 'components/navbar/notifications/notifications.html',
    controller: function ($log, $transitions, $scope, $timeout, APIService) {
        $log.info('Notifications Popup Controller');

        let lastReadNotificationTs;
        let lastReadNotificationTsWhenOpened;
        let hadUnreadWhenOpened;
        let unreadNotificationsCountWhenOpened;

        $scope.notifications = [];

        $scope.remindMeLater = function () {
            $scope.isOpen = false;
            $scope.onToggle($scope.isOpen);

            APIService.markNotificationsUnread({
                lastNotificationTs: lastReadNotificationTsWhenOpened
            }).then(() => {
                $log.info('markNotificationsUnread');
                $scope.hadUnread = hadUnreadWhenOpened;
                $scope.unreadNotificationsCount = unreadNotificationsCountWhenOpened;
                lastReadNotificationTs = lastReadNotificationTsWhenOpened;
            });
        };

        const countUnreadNotifications = function () {
            return APIService.findNotifications({
                limit: 5,
                includeUnreadCount: true,
                includeLastReadTs: true
            }).then(response => {
                $scope.unreadNotificationsCount = response.unreadCount;
                $scope.notifications = response.notifications;
                $scope.hadUnread = !!response.unreadCount;
                lastReadNotificationTs = response.lastReadTs;

                if ($scope.hadUnread) {
                    $scope.isOpen = true;
                    $scope.onToggle($scope.isOpen);
                }
            }).catch(e => {
                $log.error('failed counting notifications. ignoring', e);
            });
        };
        countUnreadNotifications();

        let markNotificationsReadTimeout;
        $scope.onToggle = function (open) {
            $timeout.cancel(markNotificationsReadTimeout);

            if (open) {
                hadUnreadWhenOpened = $scope.hadUnread;
                unreadNotificationsCountWhenOpened = $scope.unreadNotificationsCount;
                lastReadNotificationTsWhenOpened = lastReadNotificationTs;

                markNotificationsReadTimeout = $timeout(function () {
                    if ($scope.isOpen && $scope.notifications && $scope.notifications.length) {
                        $log.info('markNotificationsRead');

                        const tempLastReadNotificationTs = $scope.notifications[0].data.published_ts;

                        APIService.markNotificationsRead({
                            lastNotificationTs: tempLastReadNotificationTs
                        }).then(() => {
                            $scope.hadUnread = false;
                            $scope.unreadNotificationsCount = 0;
                            lastReadNotificationTs = tempLastReadNotificationTs;
                        });
                    }
                }, 3000);
                // Truncate notifications to maximum of two lines in the notification dropdown
                const $notificationContainers = $('.notification-container');
                $notificationContainers.each(function () {
                    const $container = $(this);
                    const $text = $container.find('.notification-text');
                    while ($text.height() > $container.outerHeight()) {
                        $text.text(function (index, text) {
                            return text.replace(/\W*\s(\S)*$/, '...');
                        });
                    }
                });
            }
        };
    }
});
